const DateInput = ({ label, id, isRequired, error, onChange, defaultValue = "", dateFormat}) => {
    const handleChange = e => {
            const value = e.target.value.replace(/\D/g, ""); // Remove non-digits
            let formattedValue = "";
            if(dateFormat === 'dmy') {
                const day = value.slice(0, 2);
                const month = value.slice(2, 4);
                const year = value.slice(4, 8);
                formattedValue = day;
                if (month) formattedValue += "/" + month;
                if (year) formattedValue += "/" + year;
            } else {
                const month = value.slice(0, 2);
                const day = value.slice(2, 4);
                const year = value.slice(4, 8);
                formattedValue = month;
                if (day) formattedValue += "/" + day;
                if (year) formattedValue += "/" + year;
            }
            e.target.value = formattedValue;
            onChange(e);
    };
    const getPlaceholder = () => {
        if (dateFormat === 'dmy') {
            return 'DD/MM/YYYY';
        } else if (dateFormat === 'mdy') {
            return 'MM/DD/YYYY';
        } else {
            return 'DD/MM/YYYY';
        }
    };
    return (
        <div style={{position: 'relative'}} className="date-input-wrapper"> 
            <input
                className={`text-input ${error ? 'error': ''}`}
                type="text"
                pattern="\d*"
                inputMode="numeric"
                placeholder={getPlaceholder()}
                maxLength="14"
                onChange={handleChange}
                id={id}
                defaultValue={ defaultValue? defaultValue: ''}
            />
            <label
                id={id}
                className={`input-label ${isRequired? 'required': ''}`}
                style={{ position: 'absolute', color: error ? 'red': '#000' }}
               >
                {label}
            </label>
            {error && (<span className="error-text">{error}</span>)}
        </div>
)};
export default DateInput;