import { connect } from 'react-redux';
import SubmissionCompleteIcon from "./DynamicColoredIcons/SubmissionCompleteIcon";
import LivenessStepIcon from "./DynamicColoredIcons/LivenessStepIcon";
import DocumentStepIcon from "./DynamicColoredIcons/DocumentStepIcon";
import POAStepIcon from "./DynamicColoredIcons/POAStepIcon";
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const testIsStepActive = (currentLocation, locationsOfOneStep) => locationsOfOneStep.some(location => currentLocation.includes(location))

const StepCircle = ({ isCompleted = false, isActive = false, isTheLastStep = false, Icon, themeColor = "#2867B2" }) => {
    let infillColor, strokeColor, iconColor;
    if (isCompleted) {
        infillColor = themeColor;
        strokeColor = themeColor;
        iconColor = 'white';
    } else {
        if (isActive) {
            infillColor = 'white';
            strokeColor = themeColor;
            iconColor = themeColor;
        } else {
            infillColor = 'white';
            strokeColor = '#C5C5C5';
            iconColor = '#C5C5C5';
        }
    }
    return (  
        <div
            className={`step-circle-wrapper`}
            style={{flex: `${isTheLastStep ? 'none' : '1'}`}}
        >                
            <Icon 
                themeColor={themeColor} 
                isComplete={isCompleted}
                isActive={isActive}
                infillColor={infillColor}
                strokeColor={strokeColor}
                iconColor={iconColor}
            />
            {(isCompleted && !isTheLastStep) && <div className="connection-line" style={{backgroundColor: themeColor}}/>}
        </div>
    )
};


function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        screenConfig: state.submissionState.screens,
        submissionState: state.submissionState.submissionState,
        branding: state.config.profile,
        brandingColor: state.config.profile.data.company_branding.primary_color
    }
};

const SubmissionFlowStepper = connect(mapStateToProps)((
    { 
        screenConfig = null,
        brandingColor,
        submissionState,
        location        
     }) => {
    const [enabledSteps, setEnabledSteps] = useState([]);
    const availableSteps = [
        {
            name: 'DOCUMENT_CAPTURE',
            isCompleted: submissionState.document_upload.completed === 1,
            isActive: testIsStepActive(location.pathname, ['/instruction', 'photo/confirm']),            
            isEnabled: screenConfig.document_upload.enabled === 1,
            Icon: DocumentStepIcon,
        },
        {
            name: 'POA_UPLOAD',
            isCompleted: submissionState.poa_upload.completed === 1,
            isActive: testIsStepActive(location.pathname, ['capture/proof_of_address', '/capture']),          
            isEnabled: screenConfig.poa_upload.enabled === 1,
            Icon: POAStepIcon,
        },
        {
            name: 'SELFIE_CAPTURE',
            isCompleted: submissionState.passive_liveness.completed === 1,
            isActive: testIsStepActive(location.pathname, ['/consent', 'capture/selfie']),           
            isEnabled: screenConfig.passive_liveness.enabled === 1,
            Icon: LivenessStepIcon,
        },
        {
            name: 'SUBMISSION_COMPLETE',
            isCompleted: testIsStepActive(location.pathname, ['/success']),
            isActive: false,
            isEnabled: true,
            Icon: SubmissionCompleteIcon
        }
    ];
    
    useEffect(() => {
        if (screenConfig) {
            const enabledSteps = availableSteps.filter(step => step.isEnabled);
            setEnabledSteps(enabledSteps);
        }
    }, [screenConfig, location]);

    return (
        <div className={`submission-flow-stepper-container ${enabledSteps.length <3 ? 'two-steps-only': ''}`} style={{flexWrap: 'wrap'}} >
            {enabledSteps.map((step, index) => (
                <StepCircle
                    key={`${step.name}-${index}`}
                    isCompleted={step.isCompleted}
                    isTheFirstStep={index === 0}
                    isTheLastStep={index === enabledSteps.length - 1}
                    Icon={step.Icon}
                    themeColor={`#${brandingColor}`}
                    isActive={step.isActive}
                />              
            ))}
        </div>
    )
});

export default withRouter(SubmissionFlowStepper);
