/**
 * @prettier
 */

import i18next from 'i18next';
import { eVerification } from '../../config/eVerification';
import { phone } from 'phone';

/**
 * Validate eVerification form submission against the form rules
 * return obj
 */

export const revalidateField = (name, value) => {    

        let error = null;
        const rule = eVerification[name];
        const formField = value;

        if (formField !== null && formField.trim() !== '') {
            // Check date is valid
            if (rule?.['type'] === 'date') {
                // Check for missing day month or year
                const dateArray = formField.split('-');
                for (let x = 0; x < dateArray.length; x++) {
                    if (dateArray[x] === '00') {
                        error = i18next.t('idpal_invalid_date_error');
                    }
                }
                // Check that date is a valid date
                const formDate = new Date(formField);
                if (formDate instanceof Date && isFinite(formDate)) {
                    if (new Date(formField) < new Date(rule['minDate'])) {
                        error = i18next.t('idpal_minimum_date_error', {
                            date: rule['minDate'],
                        });
                    } else if (new Date(formField) > new Date()) {
                        error = i18next.t('idpal_maximum_date_error');
                    } else {
                        //noop
                        //Date should be fine
                    }
                } else {
                    error = i18next.t('idpal_invalid_date_error');
                }
            }

            // Check phone is valid
            if (rule?.['type'] === 'phone') {
                if (!phone('+' + formField).isValid) {
                    error = i18next.t(
                        'idpal_please_enter_valid_number'
                    );
                }
            }
            // Check that field is above minimum character count
            if (formField.length < rule?.['min']) {
                error = i18next.t('idpal_minimum_characters_error', {
                    count: rule?.['min'],
                });
            }

            // Check that field is below maximum character count
            if (formField.length > rule?.['max']) {
                error = i18next.t('idpal_maximum_characters_error', {
                    count: rule['max'],
                });
            }

            // Check that field matches allowed characters
            if (rule?.['regex'] !== null) {
                const regex = new RegExp(rule['regex']);
                if (!regex.test(formField)) {
                    error = i18next.t(rule['messages']['regex']);
                }
            }
        }

        // Check that required field is not empty
        if (
            (formField === null || formField.trim() === '') &&
            rule?.['required']
        ) {
            error = error = i18next.t(
                'idpal_field_is_required'
            );
        }
        return error
}
function validate(formData, customFields) {
    const rules = eVerification;

    // If selected country has custom field, add to validation rules.
    if (formData['country']) {
        for (let i = 0; i < customFields.length; i++) {
            if (customFields[i]['countryName'] === formData['country']) {
                rules.customField = customFields[i];
            }
        }
    }

    const fields = Object.keys(rules);

    const errors = {};

    // Validate fields against rules
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const rule = rules[field];
        const formField = formData[field];

        if (formField !== null && formField.trim() !== '') {
            // Check date is valid
            if (rule['type'] === 'date') {
                // Check for missing day month or year
                const dateArray = formField.split('-');
                for (let x = 0; x < dateArray.length; x++) {
                    if (dateArray[x] === '00') {
                        errors[field] = i18next.t('idpal_invalid_date_error');
                    }
                }
                // Check that date is a valid date
                const formDate = new Date(formField);
                if (formDate instanceof Date && isFinite(formDate)) {
                    if (new Date(formField) < new Date(rule['minDate'])) {
                        errors[field] = i18next.t('idpal_minimum_date_error', {
                            date: rule['minDate'],
                        });
                    } else if (new Date(formField) > new Date()) {
                        errors[field] = i18next.t('idpal_maximum_date_error');
                    }
                } else {
                    errors[field] = i18next.t('idpal_invalid_date_error');
                }
            }

            // Check phone is valid
            if (rule['type'] === 'phone') {
                if (!phone('+' + formField).isValid) {
                    errors[field] = i18next.t(
                        'idpal_please_enter_valid_number'
                    );
                }
            }
            // Check that field is above minimum character count
            if (formField.length < rule['min']) {
                errors[field] = i18next.t('idpal_minimum_characters_error', {
                    count: rule['min'],
                });
            }

            // Check that field is below maximum character count
            if (formField.length > rule['max']) {
                errors[field] = i18next.t('idpal_maximum_characters_error', {
                    count: rule['max'],
                });
            }

            // Check that field matches allowed characters
            if (rule['regex'] !== null) {
                let regex = new RegExp(rule['regex']);
                if (!regex.test(formField)) {
                    errors[field] = i18next.t(rule['messages']['regex']);
                }
            }
        }

        // Check that required field is not empty
        if (
            (formField === null || formField.trim() === '') &&
            rule['required']
        ) {
            errors[field] = errors[field] = i18next.t(
                'idpal_field_is_required'
            );
        }
    }
    return errors;
}

export default validate;
