const PassportTwoSidesFlowIcon = ({fill,title}) =>  (    
    <svg
        viewBox="0 0 322 238" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        role="img" 
        aria-label={title}
    >
        <path d="M20.9227 19.16H22.0027V17H20.9227H3.08006H2.00006V19.16H2.00011V35.6388V36.7188H4.16011V35.6388V19.16H20.9227Z" fill="#C5C5C5"/>
        <path d="M22.0027 19.16H23.4427V20.6H22.0027V19.16ZM22.0027 17V15.56H23.4427V17H22.0027ZM2.00006 17H0.560062V15.56H2.00006V17ZM2.00006 19.16V20.6H0.560062V19.16H2.00006ZM2.00011 19.16V17.72H3.44011V19.16H2.00011ZM2.00011 36.7188V38.1588H0.560108V36.7188H2.00011ZM4.16011 36.7188H5.60011V38.1588H4.16011V36.7188ZM4.16011 19.16H2.72011V17.72H4.16011V19.16ZM22.0027 20.6H20.9227V17.72H22.0027V20.6ZM23.4427 17V19.16H20.5627V17H23.4427ZM20.9227 15.56H22.0027V18.44H20.9227V15.56ZM3.08006 15.56H20.9227V18.44H3.08006V15.56ZM2.00006 15.56H3.08006V18.44H2.00006V15.56ZM0.560062 19.16V17H3.44006V19.16H0.560062ZM2.00011 20.6H2.00006V17.72H2.00011V20.6ZM0.560108 35.6388V19.16H3.44011V35.6388H0.560108ZM0.560108 36.7188V35.6388H3.44011V36.7188H0.560108ZM4.16011 38.1588H2.00011V35.2787H4.16011V38.1588ZM5.60011 35.6388V36.7188H2.72011V35.6388H5.60011ZM5.60011 19.16V35.6388H2.72011V19.16H5.60011ZM20.9227 20.6H4.16011V17.72H20.9227V20.6Z" fill="#C5C5C5"/>
        <path d="M4.16001 204.08V203H2.00001V204.08L2.00001 221.944V223.024H4.16001V223.024H20.6391H21.7191V220.864H20.6391H4.16001L4.16001 204.08Z" fill="black"/>
        <path d="M4.16001 203V201.56H5.60001V203H4.16001ZM2.00001 203H0.560011V201.56H2.00001V203ZM2.00001 223.024L2.00001 224.464H0.560011L0.560011 223.024H2.00001ZM4.16001 223.024H5.60001L5.60001 224.464H4.16001L4.16001 223.024ZM4.16001 223.024H2.72001V221.584H4.16001V223.024ZM21.7191 223.024H23.1591L23.1591 224.464H21.7191L21.7191 223.024ZM21.7191 220.864V219.424H23.1591V220.864H21.7191ZM4.16001 220.864V222.304H2.72001V220.864H4.16001ZM5.60001 203V204.08H2.72001V203H5.60001ZM2.00001 201.56H4.16001V204.44H2.00001V201.56ZM0.560011 204.08V203H3.44001V204.08H0.560011ZM0.560011 221.944L0.560011 204.08H3.44001L3.44001 221.944H0.560011ZM0.560011 223.024V221.944H3.44001V223.024H0.560011ZM4.16001 224.464H2.00001L2.00001 221.584H4.16001L4.16001 224.464ZM5.60001 223.024V223.024H2.72001V223.024H5.60001ZM20.6391 224.464H4.16001L4.16001 221.584H20.6391L20.6391 224.464ZM21.7191 224.464H20.6391L20.6391 221.584H21.7191L21.7191 224.464ZM23.1591 220.864V223.024H20.2791V220.864H23.1591ZM20.6391 219.424H21.7191V222.304H20.6391V219.424ZM4.16001 219.424H20.6391V222.304H4.16001V219.424ZM5.60001 204.08L5.60001 220.864H2.72001L2.72001 204.08H5.60001Z" fill="#C5C5C5"/>
        <path d="M301.08 220.559H300V222.719H301.08H318.944H320.024V220.559H320.024V204.08V203H317.864V204.08V220.559H301.08Z" fill="#C5C5C5"/>
        <path d="M300 220.559H298.56V219.119H300V220.559ZM300 222.719V224.159H298.56V222.719H300ZM320.024 222.719H321.464V224.159H320.024V222.719ZM320.024 220.559V219.119H321.464V220.559H320.024ZM320.024 220.559V221.999H318.584V220.559H320.024ZM320.024 203V201.56H321.464V203H320.024ZM317.864 203H316.424V201.56H317.864V203ZM317.864 220.559H319.304V221.999H317.864V220.559ZM300 219.119H301.08V221.999H300V219.119ZM298.56 222.719V220.559H301.44V222.719H298.56ZM301.08 224.159H300V221.279H301.08V224.159ZM318.944 224.159H301.08V221.279H318.944V224.159ZM320.024 224.159H318.944V221.279H320.024V224.159ZM321.464 220.559V222.719H318.584V220.559H321.464ZM320.024 219.119H320.024V221.999H320.024V219.119ZM321.464 204.08V220.559H318.584V204.08H321.464ZM321.464 203V204.08H318.584V203H321.464ZM317.864 201.56H320.024V204.44H317.864V201.56ZM316.424 204.08V203H319.304V204.08H316.424ZM316.424 220.559V204.08H319.304V220.559H316.424ZM301.08 219.119H317.864V221.999H301.08V219.119Z" fill="#C5C5C5"/>
        <path d="M317.559 35.9224V37.0024H319.719V35.9224V18.0798V16.9998H317.559V16.9999H301.08H300V19.1599H301.08H317.559V35.9224Z" fill="#C5C5C5"/>
        <path d="M317.559 37.0024V38.4424H316.119V37.0024H317.559ZM319.719 37.0024H321.159V38.4424H319.719V37.0024ZM319.719 16.9998V15.5598H321.159V16.9998H319.719ZM317.559 16.9998H316.119V15.5598H317.559V16.9998ZM317.559 16.9999H318.999V18.4399H317.559V16.9999ZM300 16.9999H298.56V15.5599H300V16.9999ZM300 19.1599V20.5999H298.56V19.1599H300ZM317.559 19.1599V17.7199H318.999V19.1599H317.559ZM316.119 37.0024V35.9224H318.999V37.0024H316.119ZM319.719 38.4424H317.559V35.5624H319.719V38.4424ZM321.159 35.9224V37.0024H318.279V35.9224H321.159ZM321.159 18.0798V35.9224H318.279V18.0798H321.159ZM321.159 16.9998V18.0798H318.279V16.9998H321.159ZM317.559 15.5598H319.719V18.4398H317.559V15.5598ZM316.119 16.9999V16.9998H318.999V16.9999H316.119ZM301.08 15.5599H317.559V18.4399H301.08V15.5599ZM300 15.5599H301.08V18.4399H300V15.5599ZM298.56 19.1599V16.9999H301.44V19.1599H298.56ZM301.08 20.5999H300V17.7199H301.08V20.5999ZM317.559 20.5999H301.08V17.7199H317.559V20.5999ZM316.119 35.9224V19.1599H318.999V35.9224H316.119Z" fill="#C5C5C5"/>
        <path d="M92.8081 237.15C70.1647 237.04 51.898 218.594 52.0082 195.951L52.679 58.2102C52.7893 35.5668 71.2348 17.3 93.8782 17.4103L174.001 17.8005C196.644 17.9107 214.911 36.3562 214.8 58.9997L214.13 196.74C214.019 219.384 195.574 237.65 172.93 237.54L92.8081 237.15Z" fill={fill} fillOpacity="0.25"/>
        <g clipPath="url(#clip0_0_1)">
            <path d="M220.329 3.55078H87.6709C80.4228 3.55078 74.547 9.4331 74.547 16.6893V206.31C74.547 213.567 80.4228 219.449 87.6709 219.449H220.329C227.577 219.449 233.453 213.567 233.453 206.31V16.6893C233.453 9.4331 227.577 3.55078 220.329 3.55078Z" stroke="black" strokeWidth="10" strokeMiterlimit="10"/>
            <path d="M234.872 111.5H74.547" stroke="black" strokeWidth="10" strokeMiterlimit="10"/>
            <path d="M119.076 139.098C119.807 144.9 114.933 149.779 109.137 149.048C105.193 148.55 102.001 145.355 101.504 141.406C100.774 135.604 105.647 130.725 111.443 131.456C115.387 131.953 118.579 135.149 119.076 139.098Z" stroke="#231F20" strokeWidth="7" strokeMiterlimit="10"/>
            <path d="M102.753 150.227H117.246C122.261 150.227 126.333 154.303 126.333 159.324V171.816C126.333 172.931 125.432 173.833 124.319 173.833H95.673C94.5593 173.833 93.6583 172.931 93.6583 171.816V159.324C93.6583 154.303 97.7303 150.227 102.746 150.227H102.753Z" stroke="#231F20" strokeWidth="7" strokeMiterlimit="10"/>
            <path d="M213.235 198.143H96.1837C95.596 198.143 95.1196 198.62 95.1196 199.208C95.1196 199.797 95.596 200.274 96.1837 200.274H213.235C213.823 200.274 214.299 199.797 214.299 199.208C214.299 198.62 213.823 198.143 213.235 198.143Z" stroke="#231F20" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M212.526 135.646H140.876C140.288 135.646 139.812 136.123 139.812 136.712C139.812 137.3 140.288 137.777 140.876 137.777H212.526C213.113 137.777 213.59 137.3 213.59 136.712C213.59 136.123 213.113 135.646 212.526 135.646Z" stroke="#231F20" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M153.645 154.821H140.876C140.288 154.821 139.812 155.298 139.812 155.887C139.812 156.475 140.288 156.952 140.876 156.952H153.645C154.233 156.952 154.709 156.475 154.709 155.887C154.709 155.298 154.233 154.821 153.645 154.821Z" stroke="#231F20" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M164.286 170.446H140.876C140.288 170.446 139.812 170.923 139.812 171.511C139.812 172.099 140.288 172.576 140.876 172.576H164.286C164.874 172.576 165.35 172.099 165.35 171.511C165.35 170.923 164.874 170.446 164.286 170.446Z" stroke="#231F20" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M211.816 154.821H165.705C165.117 154.821 164.641 155.298 164.641 155.887C164.641 156.475 165.117 156.952 165.705 156.952H211.816C212.404 156.952 212.88 156.475 212.88 155.887C212.88 155.298 212.404 154.821 211.816 154.821Z" stroke="#231F20" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M152.446 92.1898C172.894 92.1898 189.47 75.5953 189.47 55.1249C189.47 34.6546 172.894 18.0601 152.446 18.0601C131.999 18.0601 115.423 34.6546 115.423 55.1249C115.423 75.5953 131.999 92.1898 152.446 92.1898Z" stroke="#231F20" strokeWidth="8" strokeMiterlimit="10"/>
            <path d="M152.574 92.2323C160.249 92.2323 166.471 75.6378 166.471 55.1674C166.471 34.6971 160.249 18.1025 152.574 18.1025C144.899 18.1025 138.677 34.6971 138.677 55.1674C138.677 75.6378 144.899 92.2323 152.574 92.2323Z" stroke="#231F20" strokeWidth="8" strokeMiterlimit="10"/>
            <path d="M115.912 44.6567L187.633 45.5232" stroke="#231F20" strokeWidth="8" strokeMiterlimit="10"/>
            <path d="M115.664 64.9468L187.392 65.8203" stroke="#231F20" strokeWidth="8" strokeMiterlimit="10"/>
        </g>
        <defs>
            <clipPath id="clip0_0_1">
                <rect width="166" height="223" fill="white" transform="translate(71)"/>
            </clipPath>
        </defs>
    </svg>
    
);

export default PassportTwoSidesFlowIcon;