const PassportOneSideFlowIcon = ({ fill, title }) =>  (
    <svg 
        viewBox="0 0 322 210" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        role="img" 
        aria-label={title}
    >
        <path d="M20.9227 4.16001H22.0027V2.00001H20.9227H3.08006H2.00006V4.16001H2.00011V20.6388V21.7188H4.16011V20.6388V4.16001H20.9227Z" fill="#C5C5C5"/>
        <path d="M22.0027 4.16001H23.4427V5.60001H22.0027V4.16001ZM22.0027 2.00001V0.560007H23.4427V2.00001H22.0027ZM2.00006 2.00001H0.560062V0.560007H2.00006V2.00001ZM2.00006 4.16001V5.60001H0.560062V4.16001H2.00006ZM2.00011 4.16001V2.72001H3.44011V4.16001H2.00011ZM2.00011 21.7188V23.1588H0.560108V21.7188H2.00011ZM4.16011 21.7188H5.60011V23.1588H4.16011V21.7188ZM4.16011 4.16001H2.72011V2.72001H4.16011V4.16001ZM22.0027 5.60001H20.9227V2.72001H22.0027V5.60001ZM23.4427 2.00001V4.16001H20.5627V2.00001H23.4427ZM20.9227 0.560007H22.0027V3.44001H20.9227V0.560007ZM3.08006 0.560007H20.9227V3.44001H3.08006V0.560007ZM2.00006 0.560007H3.08006V3.44001H2.00006V0.560007ZM0.560062 4.16001V2.00001H3.44006V4.16001H0.560062ZM2.00011 5.60001H2.00006V2.72001H2.00011V5.60001ZM0.560108 20.6388V4.16001H3.44011V20.6388H0.560108ZM0.560108 21.7188V20.6388H3.44011V21.7188H0.560108ZM4.16011 23.1588H2.00011V20.2787H4.16011V23.1588ZM5.60011 20.6388V21.7188H2.72011V20.6388H5.60011ZM5.60011 4.16001V20.6388H2.72011V4.16001H5.60011ZM20.9227 5.60001H4.16011V2.72001H20.9227V5.60001Z" fill="#C5C5C5"/>
        <path d="M4.16001 189.08V188H2.00001V189.08L2.00001 206.944V208.024H4.16001V208.024H20.6391H21.7191V205.864H20.6391H4.16001L4.16001 189.08Z" fill="black"/>
        <path d="M4.16001 188V186.56H5.60001V188H4.16001ZM2.00001 188H0.560011V186.56H2.00001V188ZM2.00001 208.024L2.00001 209.464H0.560011L0.560011 208.024H2.00001ZM4.16001 208.024H5.60001L5.60001 209.464H4.16001L4.16001 208.024ZM4.16001 208.024H2.72001V206.584H4.16001V208.024ZM21.7191 208.024H23.1591L23.1591 209.464H21.7191L21.7191 208.024ZM21.7191 205.864V204.424H23.1591V205.864H21.7191ZM4.16001 205.864V207.304H2.72001V205.864H4.16001ZM5.60001 188V189.08H2.72001V188H5.60001ZM2.00001 186.56H4.16001V189.44H2.00001V186.56ZM0.560011 189.08V188H3.44001V189.08H0.560011ZM0.560011 206.944L0.560011 189.08H3.44001L3.44001 206.944H0.560011ZM0.560011 208.024V206.944H3.44001V208.024H0.560011ZM4.16001 209.464H2.00001L2.00001 206.584H4.16001L4.16001 209.464ZM5.60001 208.024V208.024H2.72001V208.024H5.60001ZM20.6391 209.464H4.16001L4.16001 206.584H20.6391L20.6391 209.464ZM21.7191 209.464H20.6391L20.6391 206.584H21.7191L21.7191 209.464ZM23.1591 205.864V208.024H20.2791V205.864H23.1591ZM20.6391 204.424H21.7191V207.304H20.6391V204.424ZM4.16001 204.424H20.6391V207.304H4.16001V204.424ZM5.60001 189.08L5.60001 205.864H2.72001L2.72001 189.08H5.60001Z" fill="#C5C5C5"/>
        <path d="M301.08 205.559H300V207.719H301.08H318.944H320.024V205.559H320.024V189.08V188H317.864V189.08V205.559H301.08Z" fill="#C5C5C5"/>
        <path d="M300 205.559H298.56V204.119H300V205.559ZM300 207.719V209.159H298.56V207.719H300ZM320.024 207.719H321.464V209.159H320.024V207.719ZM320.024 205.559V204.119H321.464V205.559H320.024ZM320.024 205.559V206.999H318.584V205.559H320.024ZM320.024 188V186.56H321.464V188H320.024ZM317.864 188H316.424V186.56H317.864V188ZM317.864 205.559H319.304V206.999H317.864V205.559ZM300 204.119H301.08V206.999H300V204.119ZM298.56 207.719V205.559H301.44V207.719H298.56ZM301.08 209.159H300V206.279H301.08V209.159ZM318.944 209.159H301.08V206.279H318.944V209.159ZM320.024 209.159H318.944V206.279H320.024V209.159ZM321.464 205.559V207.719H318.584V205.559H321.464ZM320.024 204.119H320.024V206.999H320.024V204.119ZM321.464 189.08V205.559H318.584V189.08H321.464ZM321.464 188V189.08H318.584V188H321.464ZM317.864 186.56H320.024V189.44H317.864V186.56ZM316.424 189.08V188H319.304V189.08H316.424ZM316.424 205.559V189.08H319.304V205.559H316.424ZM301.08 204.119H317.864V206.999H301.08V204.119Z" fill="#C5C5C5"/>
        <path d="M317.559 20.9224V22.0024H319.719V20.9224V3.07982V1.99982H317.559V1.99987H301.08H300V4.15987H301.08H317.559V20.9224Z" fill="#C5C5C5"/>
        <path d="M317.559 22.0024V23.4424H316.119V22.0024H317.559ZM319.719 22.0024H321.159V23.4424H319.719V22.0024ZM319.719 1.99982V0.559818H321.159V1.99982H319.719ZM317.559 1.99982H316.119V0.559818H317.559V1.99982ZM317.559 1.99987H318.999V3.43987H317.559V1.99987ZM300 1.99987H298.56V0.559866H300V1.99987ZM300 4.15987V5.59987H298.56V4.15987H300ZM317.559 4.15987V2.71987H318.999V4.15987H317.559ZM316.119 22.0024V20.9224H318.999V22.0024H316.119ZM319.719 23.4424H317.559V20.5624H319.719V23.4424ZM321.159 20.9224V22.0024H318.279V20.9224H321.159ZM321.159 3.07982V20.9224H318.279V3.07982H321.159ZM321.159 1.99982V3.07982H318.279V1.99982H321.159ZM317.559 0.559818H319.719V3.43982H317.559V0.559818ZM316.119 1.99987V1.99982H318.999V1.99987H316.119ZM301.08 0.559866H317.559V3.43987H301.08V0.559866ZM300 0.559866H301.08V3.43987H300V0.559866ZM298.56 4.15987V1.99987H301.44V4.15987H298.56ZM301.08 5.59987H300V2.71987H301.08V5.59987ZM317.559 5.59987H301.08V2.71987H317.559V5.59987ZM316.119 20.9224V4.15987H318.999V20.9224H316.119Z" fill="#C5C5C5"/>
        <path d="M79.1402 193.176C56.4968 193.066 38.23 174.62 38.3403 151.977L38.6126 96.0629C38.7228 73.4195 57.1683 55.1528 79.8117 55.263L204.888 55.8721C227.531 55.9824 245.798 74.4279 245.688 97.0713L245.416 152.985C245.305 175.629 226.86 193.896 204.216 193.785L79.1402 193.176Z" fill={fill} fillOpacity="0.25"/>
        <g clipPath="url(#clip0_0_1)">
            <path d="M82.1859 -91.7402H242.814C251.576 -91.7402 258.705 -84.6022 258.705 -75.8302V153.79C258.705 162.562 251.576 169.7 242.814 169.7H82.1859C73.4244 169.7 66.2949 162.562 66.2949 153.79V-75.8302C66.2949 -84.6022 73.4244 -91.7402 82.1859 -91.7402Z" stroke="black" strokeWidth="10" strokeMiterlimit="10"/>
            <path d="M260.423 38.98H66.2949" stroke="black" strokeWidth="10" strokeMiterlimit="10"/>
            <path d="M120.238 72.4342C121.097 79.4002 115.171 85.3342 108.213 84.4742C103.403 83.8722 99.5372 80.0022 98.9359 75.1862C98.0769 68.1342 103.918 62.2862 110.962 63.1462C115.772 63.7482 119.637 67.6182 120.238 72.4342Z" stroke="#231F20" strokeWidth="7" strokeMiterlimit="10"/>
            <path d="M100.482 85.8501H118.005C124.104 85.8501 129 90.8381 129 96.8581V111.994C129 113.37 127.883 114.488 126.595 114.488H91.8923C90.5179 114.488 89.4012 113.37 89.4012 111.994V96.8581C89.4012 90.8381 94.3833 85.8501 100.396 85.8501H100.482Z" stroke="#231F20" strokeWidth="7" strokeMiterlimit="10"/>
            <path d="M92.4935 143.9H234.224C234.911 143.9 235.513 144.502 235.513 145.19C235.513 145.878 234.911 146.48 234.224 146.48H92.4935C91.8064 146.48 91.2051 145.878 91.2051 145.19C91.2051 144.502 91.8064 143.9 92.4935 143.9Z" stroke="#231F20" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M146.609 68.2202H233.365C234.053 68.2202 234.654 68.8222 234.654 69.5102C234.654 70.1982 234.053 70.8002 233.365 70.8002H146.609C145.922 70.8002 145.321 70.1982 145.321 69.5102C145.321 68.8222 145.922 68.2202 146.609 68.2202Z" stroke="#231F20" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M146.609 91.4399H162.071C162.758 91.4399 163.359 92.0419 163.359 92.7299C163.359 93.4179 162.758 94.0199 162.071 94.0199H146.609C145.922 94.0199 145.321 93.4179 145.321 92.7299C145.321 92.0419 145.922 91.4399 146.609 91.4399Z" stroke="#231F20" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M146.609 110.36H174.955C175.642 110.36 176.244 110.962 176.244 111.65C176.244 112.338 175.642 112.94 174.955 112.94H146.609C145.922 112.94 145.321 112.338 145.321 111.65C145.321 110.962 145.922 110.36 146.609 110.36Z" stroke="#231F20" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M176.673 91.4399H232.506C233.194 91.4399 233.795 92.0419 233.795 92.7299C233.795 93.4179 233.194 94.0199 232.506 94.0199H176.673C175.986 94.0199 175.385 93.4179 175.385 92.7299C175.385 92.0419 175.986 91.4399 176.673 91.4399Z" stroke="#231F20" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M160.61 15.5882C135.786 15.5882 115.772 -4.4498 115.772 -29.3038C115.772 -54.0718 135.786 -74.1958 160.61 -74.1958C185.435 -74.1958 205.449 -54.0718 205.449 -29.3038C205.449 -4.4498 185.435 15.5882 160.61 15.5882Z" stroke="#231F20" strokeWidth="8" strokeMiterlimit="10"/>
            <path d="M160.782 15.6741C151.505 15.6741 143.946 -4.44986 143.946 -29.2179C143.946 -54.0719 151.505 -74.1099 160.782 -74.1099C170.059 -74.1099 177.618 -54.0719 177.618 -29.2179C177.618 -4.44986 170.059 15.6741 160.782 15.6741Z" stroke="#231F20" strokeWidth="8" strokeMiterlimit="10"/>
        </g>
        <defs>
            <clipPath id="clip0_0_1">
            <rect width="201" height="172" fill="white" transform="translate(62 2)"/>
            </clipPath>
        </defs>
    </svg>
);

export default PassportOneSideFlowIcon


