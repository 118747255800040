import PhoneInput from 'react-phone-input-2';

const PhoneNumberInput = ({id, onChange, error, defaultValue, label="", initialCountry }) => (
    <div style={{position: 'relative'}}>
        <>
            <PhoneInput
                enableSearch
                disableSearchIcon
                enableLongNumbers
                onChange={onChange}
                value={defaultValue}
                country={
                    initialCountry
                        ? initialCountry.toLowerCase()
                        : ''
                }
                containerClass='phone-input-wrapper'
                inputProps={{
                    id: id,
                    name: id,
                }}
               
                inputStyle={{
                    paddingLeft: '55px',
                }}
                inputClass={`${error ? 'error': ''}`}
            />
            <label 
                className="phone-input-label required" 
                htmlFor={id}
                style={{
                    position: 'absolute',
                    zIndex: 100000,
                    color: error ? 'red': '#000',
                    
                }}
            > {label}</label>
            {error&& (<span role="alert" className="error-text">{error}</span>)} 
        </>
    </div>
);


export default PhoneNumberInput;