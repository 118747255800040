/**
 * @prettier
 */

// React packages
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import {
    getCountries,
    setErrors,
    setFormValue,
    setFormValueV2,
    setPhone,
    setSelectedCountry,
    storeAddressV2,
    submitEVerificationV2,
    setAutoAddressFocus,
    setDateErrorFocus,
} from '../../screens/actions/eVerificationActions.js';

import { clearEVerificationAddress } from '../../screens/actions/addressLookupActions.js';

// Services
import eVerificationValidatorService from '../../services/shared/eVerificationValidatorService.js';
import {
    getCompanyName,
    isSubmissionComplete,
} from '../../services/shared/helpers.js';

// Components NEW UI
import Header from '../../components/Header';
import TextInput from './components/TextInput';
import PersonalDataIcon from './dynamicColoredIcons/PersonalDataIcon';
import AddressDataIcon from './dynamicColoredIcons/AddressDataIcon';
import PhoneNumberInput from './components/PhoneNumberInput';
import DateInput from './components/DateInput';
import CustomButton from '../../components/CustomButton';
import SelectInput from './components/SelectInput';
import AddressLookupInput from './components/AddressLookupInput';
import Navigator from '../../screens/Navigator';
import StageComplete from '../../screens/Messages/StageComplete';

// Images
import loadingGif from '../../assets/gifs/loading.gif';

// Config
import {
    hiddenFormLabels,
    imageAlt,
} from '../../config/accessabilityRules.js';
import { eVerification } from '../../config/eVerification.js';
import { ACTION_LABELS } from '../../config/dataDogActionLabels.js';

/*NEW Imports*/



const ACTION = ACTION_LABELS.eVerification;
const DATE_FORMAT = process.env.REACT_APP_IS_US_REGION === 'true' ? 'mdy' : 'dmy';

const EVerificationPage = (
    {
        countries = [],
        getCountries,
        setFormValueV2,
        setAutoAddressFocus,
        setDateErrorFocus,
        selectedCountry = {},
        setSelectedCountry,
        eVerificationCompleted,
        eVerificationMode,
        clearEVerificationAddress,
        customFields = [],
        activatedCountries = [],
        companyName,
        initialCountry,
        firstName,
        lastName,
        dob,
        address1,
        address2,
        city,
        postCode,
        customField,
        phone,
        phonePrefix,
        phoneRaw,
        setErrors,
        setPhone,
        storeAddressV2,
        submitEVerificationV2,
        errors = {},
        addressLookupActive,
        addressLookupHidden,
        pendingItems,
        branding,
    }
) => {
    const { t } = useTranslation();
    const formRef = useRef(null);    
    const [isAutoAddressOpen, setIsAutoAddressOpen] = useState(false);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [navigation, setNavigation] = useState({
        action: 'load',
        props: {
            eVerificationMode: eVerificationMode,
        },
    });
    const [customFieldValue, setCustomFieldValue] = useState(() => customFields.length > 0 ? customFields[0].value : null);

    const [activeStep, setActiveStep] = useState(0);
    const brandingColor = `#${branding.data.company_branding.primary_color}`;

    const validate = formData => {
        // Set validation results
        const errors = eVerificationValidatorService(
            formData,
            customFields
        );
        setErrors(errors);
        setFocus(errors);
        // No errors, submit form
        return errors;
    }
        // Define elements that should recieve focus on error
    const setFocus = errors => {
        if (!errors) {
            return;
        }
        let focusError = null;
        // Get the first input in error
        Object.entries(errors).every(([key, value]) => {
            focusError = formRef.current.querySelector(`#${key}`);
            return focusError === null;
        });
        if (focusError) {
            focusError.focus();
        }
    }

    const handleSubmit = event =>  {
        const formData = {
            firstName:  firstName ?  firstName : '',
            lastName:  lastName ?  lastName : '',
            dob:  dob ?  dob : '',
            address1:  address1 ?  address1 : '',
            address2:  address2 ?  address2 : '',
            city:  city ?  city : '',
            postCode:  postCode ?  postCode : '',
            country:  selectedCountry.name
                ?  selectedCountry.name
                : '',
            customField:  customField ?  customField : '',
            phone:  phone ?  phone : '',
            phonePrefix:  phonePrefix ?  phonePrefix : '',
            phoneRaw:  phoneRaw ?  phoneRaw : '',
        };

        // Allign date format to ISO 8601
        let ISODateFormat = '';
        if (DATE_FORMAT === 'mdy') {
            const [month, day, year] = formData.dob.split('/');
            ISODateFormat = `${year}-${month}-${day}`;
        } else {
            // Default to dmy
            const [day, month, year] = formData.dob.split('/');
            ISODateFormat = `${year}-${month}-${day}`;
        }
        formData.dob = ISODateFormat;
        // Prevent automatic submitting of form if triggered by submit button
        if (event.preventDefault) {
            event.preventDefault();
        }

        const errors = validate(formData);
        const isFormValid = Object.keys(errors).length === 0;

       
        if (isFormValid) {
            // If the selected country is an activated country, submit to be verified
            if (
                activatedCountries.includes(
                   selectedCountry.name
                )
            ) {              
                submitEVerificationV2(formData, handleSubmitErrorCallback);

                // If it is not an activated country, then submit to be stored.
            } else {           
                storeAddressV2(formData, handleSubmitErrorCallback);
            }
        } else {
            // Switch to the next step if the first step is valid
            if(activeStep === 0 && (!errors.firstName && !errors.lastName && !errors.phone && !errors.dob)){
                // clear other errors to move to the next step
                setErrors({});
                setActiveStep(1);
                return;
            }
        }
    }

    const handleSubmitErrorCallback = (error, data) => {
        setNavigation({
            navigation: {
                action: 'error',
                props: {
                    retryAction: () => handleSubmit(data),
                    error: error,
                },
            },
        });
    }

    const selectCountry = event => {
        clearEVerificationAddress()
        setSelectedCountry(event.target.value, countries);
        // If the selected country supports address lookup, open the address lookup
        const selectedCountry = countries.find(country => country.iso_3166_3 === event.target.value);
        if(selectedCountry && !!selectedCountry.supports_address_lookup) {
            setIsAutoAddressOpen(true);
        } else {
            setIsAutoAddressOpen(false);
            setAutoAddressFocus(true);
        };
    }

    const showAddressLookup = useMemo(() => {
            if (!addressLookupActive) {
                return false;
            }
            if (addressLookupHidden) {
                return false;
            }
            if (isAutoAddressOpen) {
                return true;
            }
    },[addressLookupActive, isAutoAddressOpen, addressLookupHidden]);
    const handleInputChange = event => {
        setFormValueV2(event.target.id, event.target.value);
    }
    const handleCustomFieldChange = event => {
        setCustomFieldValue(event.target.value);
    };
    useEffect(() => {
        setFormValueV2('customField', customFieldValue);
    }, [customFieldValue]);

    useEffect(() => {
        if(countries.length === 0){
            getCountries();
        }
        setAutoAddressFocus(false);
        setDateErrorFocus(false);
        if(selectedCountry.supports_address_lookup){
            setAutoAddressFocus(true);
        }
        document.title = t('idpal_doc_title_Everification');
        if(eVerificationCompleted) {
            setNavigation({
                action: 'next',
                props: {
                    eVerificationMode: eVerificationMode,
                },
            });
        };

    }, [eVerificationCompleted]);


    useEffect(() => {
        
        // Unsets focus if autoAddress not active on first render
        if (!selectedCountry.supports_address_lookup) {
            setIsFirstRender(false);
        }
        // Clears address inputs if auto address is selected
        if (selectedCountry.supports_address_lookup) {
            clearEVerificationAddress();
            setAutoAddressFocus(true);
            // Sets focus on first name if autoAddress input if active on the first render
            if (isFirstRender) {
                setIsAutoAddressOpen(true);
                setIsFirstRender(false);
                setAutoAddressFocus(false);                                   
            }
        }       
    }, [selectedCountry]);

    if (isSubmissionComplete()) {
        return (
            <>
                <Header />
                <StageComplete
                    message={t('idpal_your_submission_is_complete', {
                        company: getCompanyName(companyName),
                    })}
                    hideContinue={true}
                    hideButton={true}
                />
            </>
        );
    };
    // Awaiting pending
    if (pendingItems > 0) {
        <div className='u-text-center start-loading'>
            <img
                alt={imageAlt.loading}
                src={loadingGif}
                className='capture'
            />
        </div>
    }
    return (
        <>
            <div className='root-layout-container e-verification-page'>
                <Header />
                {/* Header section */}
                <div className="form-step-title-container" >
                    { activeStep === 0 ? (
                        <>
                            <PersonalDataIcon fill={brandingColor}/> 
                            <h1 className="form-step-title">
                               {t('idpal_POA_personal_details_title')}
                            </h1>
                        </>
                            ):(
                        <>
                            <AddressDataIcon fill={brandingColor}/>
                            <h1 className="form-step-title">
                                {t('idpal_POA_address_details_title')}
                            </h1>
                        </>)
                    }
                </div>
            
            {/* Form section Step 1 (Personal data)*/}
            <form 
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit} 
                ref={formRef}
                style={{
                    display: 'flex', 
                    flexDirection: 'column',
                    gap:'32px',
                    height: 'inherit',
                }}
                >
                {activeStep === 0 && (
                        <>
                        <TextInput
                            label={t('idpal_first_name')}
                            placeholder={t('idpal_first_name')}
                            defaultValue={firstName}
                            isRequired
                            config={eVerification.firstName}
                            error={errors.firstName}
                            onChange={handleInputChange}
                            actionDataLabel={ACTION.lastNameInput}
                        />
                        <TextInput
                            isRequired
                            config={eVerification.lastName}
                            error={errors.lastName}
                            defaultValue={lastName}
                            onChange={handleInputChange}
                            actionDataLabel={ACTION.lastNameInput}
                            label={t('idpal_last_name')}
                            placeholder={t('idpal_last_name')}
                        />                            
                        <PhoneNumberInput
                            id={'phone'}
                            label={t('idpal_phone')}
                            error={errors.phone}
                            onChange={setPhone}
                            defaultValue={phone}
                            initialCountry={initialCountry}
                        />
                        <DateInput
                            id={eVerification.dob.id}
                            label={t('idpal_dob')}
                            isRequired
                            error={errors.dob}
                            defaultValue={dob}
                            dateFormat={DATE_FORMAT}
                            onChange={handleInputChange}
                            tabIndex={0}
                        />                       
                        <div className='e-verification-page-cta-container'>
                            <CustomButton
                                id='finish-first-step'
                                className='btn btn__arrow'
                                label={t("idpal_continue")}
                                type='submit'
                            />
                        
                        </div>
                    </>
                )}
                {activeStep === 1 && (
                    <>
                        <SelectInput
                            error={errors.country}
                            id={eVerification.country.id}
                            isRequired
                            label={t('idpal_country')}
                            placeholder={t('idpal_country')}
                            onChange={selectCountry}
                            options={countries}
                            config={eVerification.country}
                            defaultValue={
                                selectedCountry
                                    .iso_3166_3
                            }
                            actionDataLabel={
                                ACTION.countryInput
                            }
                        />

                        {showAddressLookup ?
                            (<AddressLookupInput
                                onChangeCustomField={handleCustomFieldChange}
                                customFieldValue={customFieldValue}
                            />) :
                        (<>
                            <TextInput
                                isRequired
                                config={eVerification.address1}
                                error={errors.address1}
                                onChange={handleInputChange}
                                actionDataLabel={ACTION.address1}
                                label={t('idpal_address_1')}
                                placeholder={t('idpal_address_1')}
                                defaultValue={address1}
                            />
                            <TextInput
                                config={eVerification.address2}
                                error={errors.address2}
                                onChange={handleInputChange}
                                actionDataLabel={ACTION.address2}
                                label={t('idpal_address_2')}
                                placeholder={t('idpal_address_2')}
                                defaultValue={address2}
                            />
                            <TextInput
                                config={eVerification.city}
                                error={errors.city}
                                onChange={handleInputChange}
                                actionDataLabel={ACTION.city}
                                isRequired
                                label={t('idpal_city')}
                                placeholder={t('idpal_city')}
                                defaultValue={city}
                            />
                            <TextInput
                                config={eVerification.postCode}
                                error={errors.postCode}
                                onChange={handleInputChange}
                                actionDataLabel={ACTION.postCode}
                                isRequired
                                label={t('idpal_postcode')}
                                placeholder={t('idpal_postcode')}
                                defaultValue={postCode}
                            />
                            {/*Optional custom field based on selected country*/}
                            {customFields.map(field => {
                                if (selectedCountry.iso_3166_3 ===field.iso) {
                                    return (
                                        <TextInput
                                            config={field}
                                            error={errors.customField}
                                            onChange={handleCustomFieldChange}
                                            actionDataLabel={ACTION.customField}
                                            isRequired={field.required}
                                            label={field.name}
                                            defaultValue={customFieldValue}
                                            placeholder={field.name}
                                        />
                                    )
                                }
                            })}
                        </>)}
                        <div className='e-verification-page-cta-container'>
                            <CustomButton
                                id='finish-second-step'
                                className='btn btn__arrow'
                                label={t("idpal_continue")}
                                type='submit'
                            />
                        </div>
                    </>
                )}
            </form>
        </div>
            <Navigator
                page={'e_verification'}
                action={navigation.action}
                propsToPass={navigation.props}
            />
        </>
    );
}


function mapStateToProps(state) {
    return {      
        branding: state.config.profile,      
        pendingItems: state.spinner.pendingItems,
        customFields: state.eVerification.customFields,
        countries: state.eVerification.countries,
        selectedCountry: state.eVerification.selectedCountry,
        initialCountry: state.eVerification.initialCountry,
        activatedCountries: state.eVerification.activatedCountries,
        addressLookupHidden: state.addressLookup.hidden,
        addressLookupActive: state.addressLookup.active,
        firstName: state.eVerification.firstName,
        lastName: state.eVerification.lastName,
        dob: state.eVerification.dob,
        address1: state.eVerification.address1,
        address2: state.eVerification.address2,
        city: state.eVerification.city,
        postCode: state.eVerification.postCode,
        customField: state.eVerification.customField,
        errors: state.eVerification.errors,
        phone: state.eVerification.phone,
        phonePrefix: state.eVerification.phonePrefix,
        phoneRaw: state.eVerification.phoneRaw,
        eVerificationMode: state.eVerification.mode,
        eVerificationCompleted:
            state.submissionState.submissionState.e_verification.completed,
        companyName: state.config.profile.data.company_branding.name,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setSelectedCountry,
            getCountries,
            setFormValue,
            setFormValueV2,
            setPhone,
            storeAddressV2, // New 
            submitEVerificationV2, // New         
            setErrors,
            setAutoAddressFocus,
            setDateErrorFocus,
            clearEVerificationAddress,
        },
        dispatch
    );
}

export default  connect(mapStateToProps, mapDispatchToProps)(EVerificationPage);