/**
 * @prettier
 */

// React Packages
import { useRef, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

// Services
import selectStorage from '../../services/shared/selectStorage';
import { areCookiesEnabled } from '../../services/shared/helpers';

// Other packages
import QRCode from 'react-qr-code';

// Components
import Header from '../../components/Header';
import { ChromeIcon, SafariIcon} from './components/Icons';

// Images
import loadingGif from '../../assets/gifs/loading.gif';
// Config
import { imageAlt } from '../../config/accessabilityRules';

function getBackgroundColor(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)},${parseInt(result[3], 16)},0.3)`;
  }

const MobileHandoff = ({
    pendingItems,
    branding,
}) => {
    const { t } = useTranslation();
    const primaryFocusRef = useRef(null);
    const [uuid, setUuid] = useState('');
    const brandingColor = useMemo(() => getBackgroundColor(`#${branding.data.company_branding.primary_color}`), [branding]);

    useEffect(() => {
        setUuid(selectStorage(areCookiesEnabled()).getItem('uuid'));
        // Sets document title
        document.title = t('idpal_doc_title_handoff');
        // Sets focus to primary heading on first render
        if (primaryFocusRef && primaryFocusRef.current) {
            primaryFocusRef.current.focus();
        }
    }, []);

    const buildHandoffUrl = () => {
        return (
            window.location.protocol +
            '//' +
            window.location.host +
            '?uuid=' +
            uuid +
            '&handoff_session=' +
            selectStorage(areCookiesEnabled()).getItem('sessionid') +
            '&lang=' +
            selectStorage(areCookiesEnabled()).getItem('lang') +
            '&target=webapp_mobile'
        );
    }
    if (pendingItems > 0) {
        return (
            <div className='u-text-center start-loading'>
                <img
                    alt={imageAlt.loading}
                    src={loadingGif}
                    className='capture'
                />
            </div>
        )
    }

        return (
            <div className='root-desktop-layout'>
                <Header showLanguageSelect/>
                <section className='info-box-wrapper' style={{backgroundColor: brandingColor}}>
                    <h1 className='info-box-title'>{t("idpal_mobille_handoff_main_title")}</h1>
                    <p className='info-box-secondary-text'>{t("idpal_mobille_handoff_main_subtitle")}</p>
                    <div className='info-box-scanning-instructions-wrapper'>
                        <QRCode
                            className='qr-code-img'
                            value={buildHandoffUrl()}
                            size={200}
                        />

                        <ol className='scanning-instructions-list'>
                            <span className="list-header">{t("idpal_mobille_handoff_instructions_title")}</span>
                            <li>{t("idpal_mobille_handoff_instructions_step1")}</li>
                            <li>{t("idpal_mobille_handoff_instructions_step2")}</li>
                            <li>
                                <Trans
                                    i18nKey="idpal_mobille_handoff_instructions_step3"
                                    components={{
                                        chromeIcon: <ChromeIcon />,
                                        safariIcon: <SafariIcon />,
                                    }}
                                />
                            </li>
                        </ol>
                </div>

                </section>
            </div>
        );
};

function mapStateToProps(state) {
    return {
        branding: state.config.profile,
        uuidValid: state.config.uuidValid,
        pendingItems: state.spinner.pendingItems,
    };
}

export default connect(mapStateToProps, )(MobileHandoff);
