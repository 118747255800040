/**
 * @prettier
 */

// React Packages
import { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { setInstanceID } from '../screens/actions/configActions';

const CustomButton = ({
    branding = null,
    isDisabled = false,
    className='',
    outlined = false,
    isFocused = false,
    handleClick = () => {},
    ...props}) => {
    const [colorState, setColorState] = useState(null);
    const buttonRef = useRef(null);

    const handleButtonClick = () => {
        handleClick();
    };

    useEffect(() => {
        if (branding) {
            setColorState(
                branding.data.company_branding.primary_color
                    ? branding.data.company_branding.primary_color
                    : colorState
            );
        }
    }, [branding, colorState]);


    const buttonStyles = useMemo(() => {
        let styles = {
            color: outlined? `#${colorState}` : '#ffffff',
            backgroundColor: outlined? '#ffffff': `#${colorState}`,
            boxShadow: outlined? 'none' : `inset 0px 0px 0px 2px #${colorState}`,
            border: 'none',
            fontFamily: 'Open Sans',
        };
        if(isFocused && buttonRef?.current){
            // Focus the button if modal popup is opened
            buttonRef.current.focus();
        }
        if(isDisabled){
            styles = {
                ...styles,
                backgroundColor: '#d3d3d3',
                boxShadow: 'none',
            };
        }
        return styles;
    }, [colorState, isDisabled, outlined, isFocused]);

    return (
        <button            
            ref={buttonRef}
            type={props.type}
            id={props.id}
            style={buttonStyles}
            className={`cta-button ${className}`}
            aria-label={props.label}
            tabIndex={0}
            onClick={handleButtonClick}
            disabled={isDisabled}
            data-dd-action-name={props.actionDataLabel}
        >
            {props.label}
        </button>
    );
}
function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        branding: state.config.profile,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setInstanceID }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomButton);
