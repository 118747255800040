
const DriversLicenseBackIcon = ({fill, title=""}) => {
    return (
        <svg 
            viewBox="0 0 323 210" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            role="img" 
            aria-label={title}
        >
            <path  clipRule="evenodd" d="M20.9227 4.16001H22.0027V2.00001H20.9227H3.08006H2.00006V4.16001H2.00011V20.6388V21.7188H4.16011V20.6388V4.16001H20.9227Z" fill="black"/>
            <path d="M22.0027 4.16001H23.4427V5.60001H22.0027V4.16001ZM22.0027 2.00001V0.560007H23.4427V2.00001H22.0027ZM2.00006 2.00001H0.560062V0.560007H2.00006V2.00001ZM2.00006 4.16001V5.60001H0.560062V4.16001H2.00006ZM2.00011 4.16001V2.72001H3.44011V4.16001H2.00011ZM2.00011 21.7188V23.1588H0.560108V21.7188H2.00011ZM4.16011 21.7188H5.60011V23.1588H4.16011V21.7188ZM4.16011 4.16001H2.72011V2.72001H4.16011V4.16001ZM22.0027 5.60001H20.9227V2.72001H22.0027V5.60001ZM23.4427 2.00001V4.16001H20.5627V2.00001H23.4427ZM20.9227 0.560007H22.0027V3.44001H20.9227V0.560007ZM3.08006 0.560007H20.9227V3.44001H3.08006V0.560007ZM2.00006 0.560007H3.08006V3.44001H2.00006V0.560007ZM0.560062 4.16001V2.00001H3.44006V4.16001H0.560062ZM2.00011 5.60001H2.00006V2.72001H2.00011V5.60001ZM0.560108 20.6388V4.16001H3.44011V20.6388H0.560108ZM0.560108 21.7188V20.6388H3.44011V21.7188H0.560108ZM4.16011 23.1588H2.00011V20.2787H4.16011V23.1588ZM5.60011 20.6388V21.7188H2.72011V20.6388H5.60011ZM5.60011 4.16001V20.6388H2.72011V4.16001H5.60011ZM20.9227 5.60001H4.16011V2.72001H20.9227V5.60001Z" fill="#A8A8A8"/>
            <path  clipRule="evenodd" d="M4.16001 189.08V188H2.00001V189.08L2.00001 206.944V208.024H4.16001V208.024H20.6391H21.7191V205.864H20.6391H4.16001L4.16001 189.08Z" fill="black"/>
            <path d="M4.16001 188V186.56H5.60001V188H4.16001ZM2.00001 188H0.560011V186.56H2.00001V188ZM2.00001 208.024L2.00001 209.464H0.560011L0.560011 208.024H2.00001ZM4.16001 208.024H5.60001L5.60001 209.464H4.16001L4.16001 208.024ZM4.16001 208.024H2.72001V206.584H4.16001V208.024ZM21.7191 208.024H23.1591L23.1591 209.464H21.7191L21.7191 208.024ZM21.7191 205.864V204.424H23.1591V205.864H21.7191ZM4.16001 205.864V207.304H2.72001V205.864H4.16001ZM5.60001 188V189.08H2.72001V188H5.60001ZM2.00001 186.56H4.16001V189.44H2.00001V186.56ZM0.560011 189.08V188H3.44001V189.08H0.560011ZM0.560011 206.944L0.560011 189.08H3.44001L3.44001 206.944H0.560011ZM0.560011 208.024V206.944H3.44001V208.024H0.560011ZM4.16001 209.464H2.00001L2.00001 206.584H4.16001L4.16001 209.464ZM5.60001 208.024V208.024H2.72001V208.024H5.60001ZM20.6391 209.464H4.16001L4.16001 206.584H20.6391L20.6391 209.464ZM21.7191 209.464H20.6391L20.6391 206.584H21.7191L21.7191 209.464ZM23.1591 205.864V208.024H20.2791V205.864H23.1591ZM20.6391 204.424H21.7191V207.304H20.6391V204.424ZM4.16001 204.424H20.6391V207.304H4.16001V204.424ZM5.60001 189.08L5.60001 205.864H2.72001L2.72001 189.08H5.60001Z" fill="#A8A8A8"/>
            <path clipRule="evenodd" d="M302.08 205.559H301V207.719H302.08H319.944H321.024V205.559H321.024V189.08V188H318.864V189.08V205.559H302.08Z" fill="black"/>
            <path d="M301 205.559H299.56V204.119H301V205.559ZM301 207.719V209.159H299.56V207.719H301ZM321.024 207.719H322.464V209.159H321.024V207.719ZM321.024 205.559V204.119H322.464V205.559H321.024ZM321.024 205.559V206.999H319.584V205.559H321.024ZM321.024 188V186.56H322.464V188H321.024ZM318.864 188H317.424V186.56H318.864V188ZM318.864 205.559H320.304V206.999H318.864V205.559ZM301 204.119H302.08V206.999H301V204.119ZM299.56 207.719V205.559H302.44V207.719H299.56ZM302.08 209.159H301V206.279H302.08V209.159ZM319.944 209.159H302.08V206.279H319.944V209.159ZM321.024 209.159H319.944V206.279H321.024V209.159ZM322.464 205.559V207.719H319.584V205.559H322.464ZM321.024 204.119H321.024V206.999H321.024V204.119ZM322.464 189.08V205.559H319.584V189.08H322.464ZM322.464 188V189.08H319.584V188H322.464ZM318.864 186.56H321.024V189.44H318.864V186.56ZM317.424 189.08V188H320.304V189.08H317.424ZM317.424 205.559V189.08H320.304V205.559H317.424ZM302.08 204.119H318.864V206.999H302.08V204.119Z" fill="#A8A8A8"/>
            <path clipRule="evenodd" d="M318.559 20.9224V22.0024H320.719V20.9224V3.07982V1.99982H318.559V1.99987H302.08H301V4.15987H302.08H318.559V20.9224Z" fill="black"/>
            <path d="M318.559 22.0024V23.4424H317.119V22.0024H318.559ZM320.719 22.0024H322.159V23.4424H320.719V22.0024ZM320.719 1.99982V0.559818H322.159V1.99982H320.719ZM318.559 1.99982H317.119V0.559818H318.559V1.99982ZM318.559 1.99987H319.999V3.43987H318.559V1.99987ZM301 1.99987H299.56V0.559866H301V1.99987ZM301 4.15987V5.59987H299.56V4.15987H301ZM318.559 4.15987V2.71987H319.999V4.15987H318.559ZM317.119 22.0024V20.9224H319.999V22.0024H317.119ZM320.719 23.4424H318.559V20.5624H320.719V23.4424ZM322.159 20.9224V22.0024H319.279V20.9224H322.159ZM322.159 3.07982V20.9224H319.279V3.07982H322.159ZM322.159 1.99982V3.07982H319.279V1.99982H322.159ZM318.559 0.559818H320.719V3.43982H318.559V0.559818ZM317.119 1.99987V1.99982H319.999V1.99987H317.119ZM302.08 0.559866H318.559V3.43987H302.08V0.559866ZM301 0.559866H302.08V3.43987H301V0.559866ZM299.56 4.15987V1.99987H302.44V4.15987H299.56ZM302.08 5.59987H301V2.71987H302.08V5.59987ZM318.559 5.59987H302.08V2.71987H318.559V5.59987ZM317.119 20.9224V4.15987H319.999V20.9224H317.119Z" fill="#A8A8A8"/>
            <path d="M77.9996 197.113C55.3561 197.002 37.0894 178.557 37.1997 155.914L37.4719 99.9994C37.5822 77.356 56.0277 59.0893 78.6711 59.1996L203.747 59.8086C226.391 59.9189 244.658 78.3644 244.547 101.008L244.275 156.922C244.165 179.565 225.719 197.832 203.076 197.722L77.9996 197.113Z" fill={fill} fillOpacity="0.25"/>
            {/* <path d="M77.9996 197.113C55.3561 197.002 37.0894 178.557 37.1997 155.914L37.4719 99.9994C37.5822 77.356 56.0277 59.0893 78.6711 59.1996L203.747 59.8086C226.391 59.9189 244.658 78.3644 244.547 101.008L244.275 156.922C244.165 179.565 225.719 197.832 203.076 197.722L77.9996 197.113Z" stroke="black"/> */}
            <g clip-path="url(#clip0_0_1)">
                <path d="M243.917 39.7368H80.0833C71.0495 39.7368 63.7261 47.0815 63.7261 56.1416V157.858C63.7261 166.918 71.0495 174.263 80.0833 174.263H243.917C252.951 174.263 260.274 166.918 260.274 157.858V56.1416C260.274 47.0815 252.951 39.7368 243.917 39.7368Z" stroke="black" stroke-width="8" stroke-miterlimit="10"/>
                <path d="M157.072 133.952H85.3463V152.636H157.072V133.952Z" fill="black" stroke="black" stroke-width="4" stroke-miterlimit="10"/>
                <path d="M238.579 148.899H171.511C170.739 148.899 170.114 149.527 170.114 150.301C170.114 151.075 170.739 151.702 171.511 151.702H238.579C239.351 151.702 239.977 151.075 239.977 150.301C239.977 149.527 239.351 148.899 238.579 148.899Z" stroke="black" stroke-width="4" stroke-miterlimit="10"/>
                <path d="M238.551 95.3315H84.685C83.9133 95.3315 83.2877 95.9589 83.2877 96.7329C83.2877 97.5068 83.9133 98.1342 84.685 98.1342H238.551C239.323 98.1342 239.949 97.5068 239.949 96.7329C239.949 95.9589 239.323 95.3315 238.551 95.3315Z" stroke="black" stroke-width="4" stroke-miterlimit="10"/>
                <path d="M238.551 110.279H84.685C83.9133 110.279 83.2877 110.907 83.2877 111.681C83.2877 112.455 83.9133 113.082 84.685 113.082H238.551C239.323 113.082 239.949 112.455 239.949 111.681C239.949 110.907 239.323 110.279 238.551 110.279Z" stroke="black" stroke-width="4" stroke-miterlimit="10"/>
                <path d="M259.072 62.0176H67.1819C66.4102 62.0176 65.7847 62.645 65.7847 63.4189V79.3005C65.7847 80.0744 66.4102 80.7018 67.1819 80.7018H259.072C259.844 80.7018 260.47 80.0744 260.47 79.3005V63.4189C260.47 62.645 259.844 62.0176 259.072 62.0176Z" fill="black" stroke="black" stroke-width="4" stroke-miterlimit="10"/>
                <path d="M238.579 133.952H171.511C170.739 133.952 170.114 134.58 170.114 135.353C170.114 136.127 170.739 136.755 171.511 136.755H238.579C239.351 136.755 239.977 136.127 239.977 135.353C239.977 134.58 239.351 133.952 238.579 133.952Z" stroke="black" stroke-width="4" stroke-miterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                <rect width="204" height="142" fill="white" transform="translate(60 36)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default DriversLicenseBackIcon;