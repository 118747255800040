import { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation, Trans } from 'react-i18next';
import { submissionStarted } from '../screens/actions/submissionStateActions';
import Header from '../components/Header';
import CustomButton from '../components/CustomButton';
import Navigator from '../screens/Navigator';
import idCheckIcon from '../assets/img/idCheck.svg';
import { getCompanyName } from '../services/shared/helpers';


const LinkText = ({ linkTo = "#", title = "", ...props }) => {
    return (<a href={linkTo} target="_blank" title={title} aria-label={{title}} rel="noreferrer">
        {props.children}
    </a> )
};


const StartPage = ({ 
        branding = null,
        submissionStarted,
        submission,
        policies
    }) => {
    const { t } = useTranslation();
    const primaryFocusRef = useRef();
    
    const [navigationState, setNavigationState] = useState({
        action: 'load',        
    });    
    const handleClick = () => {
        setNavigationState({
            action: 'next',            
        });
        submissionStarted();       
    };

    useEffect(() => {
        primaryFocusRef.current.focus();
        return () => null
    }, [primaryFocusRef]);

    useEffect(() => {
        submission.new?  
            document.title = t('idpal_doc_title_start'): 
                document.title = t('idpal_doc_title_continue')
                return () => null
    }, [submission.new]);

    return (
        <div className="root-layout-container">
            <Header showLanguageSelect/>
            <div className='start-logo-container'>
                <div className='icon-group-wrapper'>                   
                    <svg
                        alt='Start Screen Ellipse Icon'
                        className='ellipse-icon'
                        viewBox="0 0 177 177">
                        <circle cx="88.5" cy="88.5" r="88.5" fill={`#${branding.primary_color}`} fillOpacity="0.3"/>
                    </svg>
                    <img
                        src={idCheckIcon}
                        className='card-icon'
                        alt='Start Screen Document Icon'
                    />
                </div>
            </div>
            <div className='start-content-wrapper'>
                <h1 
                    ref={primaryFocusRef}
                    tabIndex={0}
                    className="start-text-container">
                    <Trans
                        i18nKey='idpal_start_screen_default'
                        values={{companyName: getCompanyName(branding.name)}}
                        components={{
                            br: <br />,
                        }}
                    />
                </h1>
                <p className="start-privacy-policy-text">                    
                    <Trans
                        i18nKey='idpal_terms_and_conditions_warning'
                        values={{sessionState: submission.new? t('idpal_start').toLowerCase() : t('idpal_continue').toLowerCase()}}
                        components={{ 
                        link1: <LinkText linkTo={policies.terms_and_conditions} title={'Terms and Conditions'}/>,
                        link2: <LinkText linkTo={policies.privacy_policy} title={'here'}/>,
                        }}
                    />   
                </p>                
            </div>

            <div className="start-cta-container">
                <CustomButton 
                        label={submission.new? t('idpal_start') : t('idpal_continue')} 
                        id="start-cta-button"
                        handleClick={handleClick}
                        aria-label={submission.new? t('idpal_start') : t('idpal_continue')}
                    />              
            </div>

            <Navigator
                page={'start_screen'}
                action={navigationState.action}
                propsToPass={null}
            />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        submission: state.submissionState.details,
        branding: state.config.profile.data.company_branding,
        policies: state.config.profile.data.policies,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ submissionStarted }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StartPage);
